import React from "react";
import { FaEnvelope, FaLinkedin } from "react-icons/fa";
import heroImage from "../assets/images/hero.png";

function Home() {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center text-center gap-10 p-[20px]">
      <small className="tracking-[5px] text-slate-200">
        "I could either watch it happen or be a part of it." — Elon Musk
      </small>
      <img
        src={heroImage}
        alt="hero"
        className="w-[100px] md:w-[250px] rounded-full overflow-hidden bg-green-200"
      />
      <div className="flex items-center gap-5">
        <a
          href="https://linkedin.com/in/4mr"
          target="_blank"
          className="hover:-translate-y-5 transition-all ease-in-out duration-500"
          rel="noreferrer"
        >
          <FaLinkedin className="text-4xl" />
        </a>
        <a
          href="mailto:contact@amrtarek.com"
          className="hover:-translate-y-5 transition-all ease-in-out duration-500"
        >
          <FaEnvelope className="text-4xl" />
        </a>
      </div>
    </div>
  );
}

export default Home;
