import React from "react";

function NotFound() {
  return (
    <div className="container">
      <h1>404.</h1>
      <h2>It is not you, It is us. Technically, Just Me.</h2>
    </div>
  );
}

export default NotFound;
