import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap-grid.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFound from "./pages/NotFound";
import { MantineProvider } from "@mantine/core";
import Home from "./pages/Home";

function App() {
  return (
    <MantineProvider theme={{ colorScheme: "dark" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  );
}

export default App;
